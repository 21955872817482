import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/common/layout/layout';
import SEO from '../../components/common/layout/seo';
import Navigation from '../../components/common/navigation/navigation';
import { Container, Section } from '../../components/global';
import Footer from '../../components/sections/footer';

const AccountingPage = () => {
  return (
    <Layout>
      <SEO title="Accounting" />
      <Navigation />

      <StyledSection style={{ marginTop: '66px' }}>
        <SummaryContainer>
          <CardContainer>
            <SummaryTitle>Accounting</SummaryTitle>
            <CardContent>
              Most accounting systems have a hard time aggregating AP and AR transactions to account
              for the purchase and sale of a single item. Project modules, custom attributes, or
              clever naming conventions can be used to group the transactions, but doesn’t provide
              the direct link that says “this paid for that”.
            </CardContent>
            <CardContent>
              Deal Insights Deal Map lets you run analyses by Deal, Agent, and other attributes that
              are hard to track in traditional systems. (reports/dashboard)
            </CardContent>
            <h5>Escrow</h5>
            <CardContent>
              Escrow Deal Map helps make sure you know what your escrow balance is on each deal.
              Easily identify deals set up with a non-zero escrow balance. (report of escrow by
              deal) Deal Map allows you to be certain that when money goes out of your escrow
              account, it is going to pay for the same exact item that you have already received
              money for. (deal map with similar transactions that might be confusing)
            </CardContent>
            <h5>Manage Existing Invoices and Transactions</h5>
            <CardContent>
              Managing a multi-party deal is no party. Money is wired, cash changes hands, invoices
              are sent, then modified and re-sent, checks are hand-written, and nothing goes to
              plan. It is not easy, and can be time-consuming and difficult to track. We take the
              unmanageable mess of invoices, credit notes, and direct payments, and put them into
              the Unmapped Transactions queue. Search to look for the deal that transaction is
              associated with and map it with one click. The system will find the relevant deal
              transaction, or create a new one, based on the details of the transaction. Didn’t
              bother to create a Deal in the first place? Line up the transactions and the deal will
              be created for yo
            </CardContent>
          </CardContainer>
        </SummaryContainer>
      </StyledSection>

      <Footer />
    </Layout>
  );
};

export default AccountingPage;

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  @media (max-width: ${(props) => props.theme.screen.md}) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const CardContainer = styled.div`
  width: 500px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 700px;
    margin-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 80%;
  }
`;
const CardContent = styled.p`
  line-height: 1.6;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    text-align: center;
  }
`;
const SummaryContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // padding: 80px 0 40px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    img {
      width: 90%;
    }
  }
`;

const SummaryTitle = styled.h3`
  color: #01a1e8;
  margin: 0 auto 32px;
  text-align: center;
`;
